<template>
  <div id="coupon_index">
    <top-tab
      :tapList="tapList"
      @thisTabClick="thisTabClick"
      :thisTab="thisTab"
      :searchBoo="false"
      style="margin: 10px auto"
    >
    </top-tab>
    <no-data v-if="couponData.length == 0"></no-data>

    <van-list
      v-else
      error-text="请求失败，点击重新加载"
      v-model="loading"
      :finished="finished"
      finished-text="已无更多明细"
      @load="onLoad"
    >
      <div class="meCoupon">
        <template v-for="item in couponData">
          <list :coupon="item" v-if="item.voucher_state <= 1"></list>
        </template>
      </div>
    </van-list>

    <div class="fixed_bar flex-container">
      <div class="li flex1" @click="$router.push({ name: 'couponHistory' })">
        查看历史券
      </div>
      <div
        class="li flex1 centre"
        @click="$router.push({ name: 'couponCentre' })"
      >
        领券中心
      </div>
    </div>
  </div>
</template>
<script>
import { getVoucherList } from "./js/";
import list from "./list.vue";
import { List } from "vant";
import topTab from "@/components/topTab/";
import noData from "@/components/noData/";
export default {
  name: "",
  components: {
    list,
    topTab,
    noData,
    vanList: List,
  },
  data: () => ({
    page: 1,
    per_page: 10,
    loading: false,
    finished: false,
    couponData: [],
    thisTab: "all",
    tapList: [
      {
        title: "全部",
        type: "all",
        state_type: "1",
      },
      {
        title: "红包",
        type: "hongbao",
        state_type: "2",
      },
      {
        title: "优惠券",
        type: "quan",
        state_type: "3",
      },
    ],
  }),
  created() {
    //do something after creating vue instance
    this.onLoad();
  },
  methods: {
    async onLoad() {
      this.loading = true;
      let { voucher_list } = await getVoucherList(this.page, this.per_page);
      this.couponData.push(...voucher_list);
      this.page++;
      if (voucher_list.length < this.per_page) {
        this.loading = true;
        this.finished = true;
      } else {
        this.loading = false;
      }
    },
    thisTabClick(type) {
      if (type == 2) {
        this.page = 1;
        this.couponData = [];
        this.time_type = type;
        return false;
      }

      this.time_type = type;
      this.page = 1;
      this.loading = false;
      this.finished = false;
      this.couponData = [];
      this.onLoad();
    },
  },
};
</script>
<style lang="scss" scoped>
@import "~@css/mixin.scss";

#coupon_index {
  margin-bottom: rem(150);
}

.fixed_bar {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: rem(150);
  background: #ffffff;
  justify-content: center;
  align-items: center;
  box-shadow: 0px rem(-4) rem(20) 0px rgba(204, 204, 204, 0.15);
  .li {
    text-align: center;
    color: #343434;
    font-size: rem(28);
    font-weight: bold;
  }
  .centre {
    color: #ff4848;
  }
}
</style>
